@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

*{
  font-family: 'Poppins', sans-serif !important;
  }
  .overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.405);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
  }
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  background-color: #F4F8FB !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.big-container{
  background-color: #F4F8FB !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 120vh;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}
.boost-container {
  position: absolute;
  left: 50%;
  transform: translateX(-40%);
  width: 70%;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 40px;
  transition: 'width 0.3s ease',
}
button, a {
  cursor: pointer !important;
}
.boost-container-closed {
  position: absolute;
  left: 50%;
  transform: translateX(-46%);
  width: 90%;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 40px;
  transition: 'width 0.3s ease',
}
.notification-schedule{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: white;
  z-index: 100;
  border: 2px solid #3471CC;
  border-radius: 10px;
  padding: 10px;
  min-width: 20%;
}
.notification-schedule h2 {
  font-size: 17px;
}
.notification-schedule > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-schedule > div > p {
  font-weight: 700;
  font-size: 18px;
  background-color: #3471CC;
  border-radius: 50%;
  color: white;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 30px;
  transition: 0.2s all;
}
.notification-schedule-close{
  transform: translateX(20%);
  transition: 0.2s all;
}
.open-schedule-notif{
  height: 50px;
  padding: 20px;
  position: fixed;
  right: 0;
  bottom: 2rem;
  transition: 0.2s all;
  background-color: #3471CC;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.open-schedule-notif > * {
  color: white;
}
@media screen and (max-width: 1400px) {
  .notification-schedule{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: white;
    z-index: 100;
    border: 2px solid #3471CC;
    border-radius: 10px;
    padding: 10px;
    min-width: 20%;
  }
  .notification-schedule h2 {
    font-size: 15px;
  }
  .notification-schedule >p {
    font-size: 13px;
  }
  .notification-schedule > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notification-schedule > div > p {
    font-weight: 700;
    font-size: 16px;
    background-color: #3471CC;
    border-radius: 50%;
    color: white;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 30px;
    transition: 0.2s all;
  }
  .notification-schedule-close{
    transform: translateX(20%);
    transition: 0.2s all;
  }
  .open-schedule-notif{
    height: 50px;
    padding: 20px;
    position: fixed;
    right: 0;
    bottom: 2rem;
    transition: 0.2s all;
    background-color: #3471CC;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
@media screen and (max-width: 700px) {
  body{
    overflow-y: auto;
    width: 100%;
  }
  .container-mobile{
    width: 100%;
    padding-bottom: 40px;
  }
  .App {
    overflow-x: hidden;
    width: 100% !important;
  }
  .big-container{
    overflow-x: hidden;
  }
}

