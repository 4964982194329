:root {
  --bgcolor: #F4F8FB
}


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: var(--bgcolor);
}