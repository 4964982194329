.topDash {
    justify-content: flex-end;

    #lasttopdashitem #dwrep {
            margin-top: 0;
    }
}
.wrapper-maxi{
    background-color: white;
    border-radius: 28px;
    padding: 20px;
    box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.07);
}

.dashbody {
    transform: translateY(-20px);
}

.dashbody {
    .datefilter {
        h5 {
            color: gray;
        }

        #datewrapper {
            display: flex;
            gap: 2rem;

            label {
                font-size: 20px;
                margin-right: 10px;
                font-weight: 500;
            }

            input {
                font-size: 20px;
                border: none;
                color: #3471CC;
                background-color: transparent;
                position: relative;
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }
        }
    }
}

.dashbody .firstrowdash {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
}

.dashbody .tit {
    font-size: 25px;
    font-weight: 500;
}

.dashbody .tit font {
    color: #3471CC;
}
.coming-soon{
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.274);
    margin: 10px 0 30px 0;
    background-color: #3471CC;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 26px;
    padding: 30px 0;
}
.dash2 .wrapper {
    display: flex;
    align-items: stretch;
    width: fit-content;
    gap: 3rem;
}
.wrapper-maxi{
    background-color: white;
    border-radius: 28px;
    padding: 30px 30px 30px 75px;
    box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.07);
}

.dash2 .wrapper canvas {
    height: 400px !important;
    width: 400px !important;
}

.dash2 .wrapper .leftdatas {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.dash2 .wrapper .leftdatas .datatit {
    font-size: 15px;
    color: gray;
    font-weight: 600;
}

.dash2 .wrapper .leftdatas .dataperc {
    font-size: 27px;
    font-weight: 600;
}

.dash2 .wrapper .leftdatas>div {
    position: relative;
}

.dash2 .wrapper .leftdatas>div::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    top: 0;
    left: -45px;
    border-radius: 100%;
    background-color: red;
}

.dash2 .wrapper .leftdatas>div:nth-child(2)::before {
    background-color: #E849D8;
}

.dash2 .wrapper .leftdatas>div:nth-child(5)::before {
    background-color: #655528;
}

.dash2 .wrapper .leftdatas>div:nth-child(6)::before {
    background-color: #FBBC05;
}

.dash2 .wrapper .leftdatas>div:nth-child(8)::before {
    background-color: #30978B;
}
.dash2 .wrapper .leftdatas>div:nth-child(1)::before {
    background-color: #000000;
}
.dash2 .wrapper .leftdatas>div:nth-child(4)::before {
    background-color: #3471CC;
}
.dash2 .wrapper .leftdatas>div:nth-child(7)::before {
    background-color: #01594f;
}
.dash2 .wrapper .leftdatas>div:nth-child(3)::before {
    background-color: #973030;
}


.secondrowdash {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.secondrowdash canvas {
    width: 40vw !important;
}

.secondrowdash .subtit {
    position: relative;
    font-size: 25px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.38);
    margin-left: 40px;
}

.secondrowdash .subtit::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    top: 4px;
    left: -40px;
    background-color: #5388D8;
}


.secondrowdash .rightdash {
    width: fit-content;
    background-color: white;
    border-radius: 28px;
    padding: 30px;
    box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.07);
}

.secondrowdash .rightdash .tit {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
}

.secondrowdash .rightdash .number {
    color: #3471CC;
    font-size: 47px;
    font-weight: 500;
}

.secondrowdash .rightdash .costirow:nth-child(2) .tit font {
    color: #30978B;
}

.secondrowdash .rightdash .costirow:nth-child(2) .number {
    color: #30978B;
    font-size: 47px;
    font-weight: 500;
}





@media screen and (width <=1650px) {
    .dashbody {
        .datefilter {
            margin-bottom: 30px !important;
        }

        .firstrowdash {
            flex-wrap: wrap;
            gap: 60px !important;

            .dash2 {
                width: 100%;

                .wrapper {
                    width: 100% !important;
                    justify-content: center;
                }
            }
        }

        .secondrowdash {
            margin-top: 60px;
            flex-wrap: wrap;

            .leftdash,
            .rightdash {
                width: 100%;

                canvas {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            .rightdash {
                display: flex;
                justify-content: space-around;

            }
        }
    }
}


@media screen and (width <=1200px) {
    .dashbody {
        margin-top: 50px;

        .datefilter {
            * {
                font-size: 18px !important;
            }
        }
    }
}


@media screen and (width <=900px) {
    .dashbody {
        .wrapper {
            .rightdatas {
                canvas {
                    height: 300px !important;
                }
            }
        }

        .datefilter {
            margin-bottom: 30px;
        }
    }
}

@media screen and (width <=600px) {
    .dashbody {

        .datefilter {
            #datewrapper {
                flex-direction: column !important;
            }

            * {
                font-size: 15px !important;
            }
        }

        .firstrowdash {

            .dash2 {
                .wrapper {
                    flex-wrap: wrap !important;
                    padding: 10px !important;
                    column-gap: 1rem !important;
                    align-items: flex-end !important;

                    .leftdatas {
                        flex-direction: row !important;
                        gap: 0.2rem !important;

                        * {
                            font-size: 11px !important;
                        }

                        div::before {
                            top: 100% !important;
                            left: 0 !important;
                            height: 20px !important;
                            width: 20px !important;
                        }
                    }
                }
            }
        }

        .secondrowdash {
            .subtit {
                font-size: 20px !important;

                &::before {
                    height: 20px !important;
                    width: 20px !important;
                }
            }

            .rightdash {
                flex-direction: column;
            }
        }

    }
}