.calendar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    width: 100%;
    margin-top: 40px;
}
.my-calendar{
    height: 78vh;
    width: 100%;
}
.fc-day {
    background-color: rgba(255, 255, 255, 0); /* Cambia il colore di sfondo dei giorni */
  }
  .fc-event {
    background-color: transparent; /* Cambia il colore di sfondo degli eventi */
    color: #fff; 
    border-color: transparent; /* Cambia il colore del bordo degli eventi */
  }  
  .fc-day-header {
    background-color: #007bff; /* Cambia il colore di sfondo */
    color: #fff; /* Cambia il colore del testo */
    text-decoration: none;
  }
  .fc-daygrid-day-number{
    text-decoration: none;
    color: #3471CC;
  }
  .event-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Allinea al centro orizzontalmente */
    text-align: start; /* Allinea il testo al centro */
    padding: 2px;
    position: relative;
    width: 100%;
    background-color: #3471CC;
    min-height: 50px;
    border-radius: 2px;
    padding: 3px;
  }
  .chatbot-calendar{
    background-color: green !important;
    border-color: green;
  }
  .fc-col-header-cell-cushion{
    font-size: 14px !important;
    font-weight: 500;
    text-decoration: none !important;
    color: #3471CC;
  }
  .fc-scrollgrid-shrink{
    font-size: 14px;
  }
  .event-title {
    font-weight: 500; /* Testo in grassetto per il titolo */
    margin-bottom: 0px; /* Margine inferiore per separare il titolo dalla descrizione */
    font-size: 12px;
    width: 80%;
    line-height: 13px;
  }
  
  .event-description {
    font-size: 12px;
    color: #ffffff; /* Colore grigio per la descrizione */
    font-weight: 400;
  }
  .close-icon-calendar{
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    background-color: white;
    color: #3471CC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
  }
  .popup-orientatore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 50%;
    z-index: 10000;
    margin-top: 10vh;
    margin-left: 10vw;
    position: fixed;
    background-color: white;
    border-radius: 20px;
    padding: 50px 20px;
    box-shadow: 0px 0px 20px 2000px rgba(0, 0, 0, 0.77)
}
.shadow-popup-modify{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -20px;
  left: 0px;
  bottom: 0;
  right: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000000;
  border-radius: 0px;
}
  .popup-container-calendar{
    background-color: white;
    border-radius: 10px;
    z-index: 1000;
    width: 20%;
    height: auto;
    padding: 20px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.289);
}
  .select-calendar{
    border: 2px solid #3471CC;
    margin-left: 20px;
    padding: 6px 12px;
    border-radius: 5px;
  }
  .iniziali-icon-calendar{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    max-width: 20px;
    background-color: white;
    height: 20px;
    font-size: 10px;
    max-height: 20px;
    right: 2px;
    bottom: 0px;
    color: #3471CC;
    font-weight: 500;
  }
  .shadow-recall{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.742);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  }
  .delete-recall-popup > h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .delete-recall-popup > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .delete-recall-popup > div > button:first-of-type{
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #3471CC;
  }
  .delete-recall-popup > div > button:last-of-type{
    background-color: #3471CC;
    border-radius: 50%;
    padding: 4px 10px;
    cursor: pointer;
    color: white;
    border: none;
  }